$(function(){
	var $win = $(window);
	$win.on('load scroll resize', function () {
		winH = $win.outerHeight();
    winW = $win.outerWidth();
    scroll = $win.scrollTop();
		// $('.imgslider').css('height', `${winH}px`);
		$('.character01').on('click',function(){
			$('.imgslider01').show();
			$('.mcpi').addClass('posAbsolute');
			$('body').css('overflow','hidden');
			$('.posAbsolute').css('top', `${-scroll}px`);
		});
		// modal
		$('.character01').on('click',function(){
			$('.characterBoxNum01').css('opacity','1').css('display','block');
		});
	});
});

$(function(){
	var $win = $(window);
	$win.on('load scroll resize', function () {
		winH = $win.outerHeight();
    winW = $win.outerWidth();
    scroll = $win.scrollTop();
		// $('.imgslider').css('height', `${winH}px`);
		$('.character02').on('click',function(){
			$('.imgslider02').show();
			$('.mcpi').addClass('posAbsolute');
			$('body').css('overflow','hidden');
			$('.posAbsolute').css('top', `${-scroll}px`);
			// var imgNum = $(this).find('.imgNum').html();
			// $('.sliderImgContainer-deb').css('display', 'none').css("opacity","0");
			// $(`.shownum${imgNum}`).css('display', 'block').css("opacity","1");
		});
		// modal
		$('.character02').on('click',function(){
			$('.characterBoxNum02').css('opacity','1').css('display','block');
		});
	});
});

// スライド上の左右をクリックし前後のスライドへ切り替えるスライドショー
$(function() {
	$('.clickSilder').on('click',function(){
			var imgNum = $(this).find('.imgNum').html();
		$('.jquery-cycle' ).cycle({
				fx: 'fade',
				speed: 1000,
				timeout: 0,
				sync: 1,
				next: '.jquery-cycle-next',
				prev: '.jquery-cycle-prev',
				startingSlide: imgNum - 1
		});
	});
    $( '.imgSliderIndex').hover(function() {
			 $( '.jquery-cycle-nav' ).fadeIn();
		 },function() {
			 $( '.jquery-cycle-nav' ).fadeOut();
		 }
    );
});



// スライダーもとに戻す

$(function(){
	$('.sliderClosing').find('a').on('click',function(){
		$('.imgslider').fadeOut(600);
		$('.imgSliderIndex').fadeOut(600).css('opacity','0').css('transition-duration','.6s');
		$('.mcpi').removeClass('posAbsolute');
		$('body').css('overflow','scroll');
	});
	$('.jquery-cycle' ).cycle({
			fx: 'fade',
			speed: 1000,
			timeout: 0,
			sync: 1,
			next: '.jquery-cycle-next',
			prev: '.jquery-cycle-prev',
			startingSlide: 0
	});
});
